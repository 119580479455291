import React, { useContext, useEffect, useMemo } from "react";
import { graphql, PageProps } from "gatsby";

import { useLanguageContext, LANGUAGE_ACTIONS } from "@/context";
import TeamMemberSection from "@/components/sections/TeamMemberSection";
import { parseLayout, parseTeamMember } from "@/base/helpers/parsers";
import { useStoryblok } from "@/base/lib/storyblok";
import Layout from "../layouts/Layout";
import TeamMemberTile from "../elements/TeamMemberTile";
import NavButton from "../elements/NavButton";
import Button from "../elements/Button";
import InvestmentsSection from "../sections/InvestmentsSection";
import RelatedNewsSection from "../elements/RelatedNewsSection";

interface PageData {
  layout: StoryblokStory;
  sectionInvestmentsSettings: {
    edges: {
      node: { name: string; value: string };
    }[];
  };
}

type ExtendedPageContext = PageContext<MemberType> & {
  news: NewsPostType[];
  videos: SingleVideoType[];
  slug: string;
  exampleTeamMembers: (ParsedStoryblokStory & { content: MemberType })[];
};

export default function TeamMember({
  pageContext,
  location,
  data,
}: PageProps<PageData, ExtendedPageContext>) {
  const {
    lang,
    languages,
    story,
    alternates,
    news,
    videos,
    slug,
    exampleTeamMembers,
  } = pageContext;
  const { dispatch } = useContext(useLanguageContext);
  const parsedLayoutContent = parseLayout(data.layout);
  const pageStory = useStoryblok<MemberType>(story, location);
  const { teamMember, relatedItems } = useMemo(() => {
    const teamMember = parseTeamMember(pageStory);
    const exampleNews = news.slice(0, 3);
    const exampleVideos = videos.slice(0, 3);

    const allRelatedItems = [
      ...exampleNews.map((item) => ({
        ...item,
        date: new Date(item.content.post_date),
      })),
      ...exampleVideos.map((item) => ({
        ...item,
        date: new Date(item.content.video_date),
      })),
    ].sort((a, b) => b.date.getTime() - a.date.getTime());
    const relatedItems = allRelatedItems.slice(0, 3);
    return { teamMember, relatedItems };
  }, [slug, news, videos, pageStory]);

  const investmentsSettings = useMemo(() => {
    return Object.fromEntries(
      data.sectionInvestmentsSettings.edges.map((datasource) => [
        datasource.node.name,
        datasource.node.value,
      ])
    );
  }, [data.sectionInvestmentsSettings]);

  const { header, footer } = parsedLayoutContent || {};

  if (teamMember == null) {
    return (
      <div>
        <h3>Team member not found</h3>
      </div>
    );
  }
  if (story == null) {
    return (
      <div>
        <h3>Editor page</h3>
      </div>
    );
  }

  useEffect(() => {
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguage,
      payload: lang,
    });
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguages,
      payload: languages,
    });
  }, []);

  const showInvestments = teamMember.companies?.length > 0;

  const seo = {
    title: `${teamMember.name} - Vertex Team Member`,
    description: `${teamMember.name} - ${teamMember.teamMemberPosition}`,
    imageUrl: teamMember.photo.image,
  };

  return (
    <Layout
      header={header && header[0]}
      footer={footer && footer[0]}
      simpleSeo={seo}
      siteName={story.name}
      pathname={location.pathname}
      hash={location.hash}
      alternates={alternates}
    >
      <TeamMemberSection teamMember={teamMember} />
      {showInvestments && (
        <InvestmentsSection
          {...investmentsSettings}
          companies={teamMember.companies}
        />
      )}
      {relatedItems.length > 0 && (
        <RelatedNewsSection
          relatedItems={relatedItems}
          showVideos
          allNewsHref={`/news/author/${teamMember.slug}`}
          isLinkingFromDifferentThanNews
        />
      )}
      <section className="mx-auto max-w-x-big px-4 py-24">
        <div className="flex justify-between items-center mb-16">
          <h2 className="mb-0">Other team members</h2>
          <NavButton
            className="max-md:hidden"
            title="See our team"
            href="/#team"
            toRight
          />
        </div>
        <ul className="flex gap-4 md:gap-6 max-w-x-big lg:gap-8 2xl:gap-12 max-md:flex-wrap">
          {exampleTeamMembers.map((teamMember) => (
            <TeamMemberTile
              href={`/team-members/${teamMember.slug}`}
              member={teamMember.content}
              key={teamMember.uuid}
            />
          ))}
        </ul>
        <Button type="link" to="/#team" className="md:hidden mt-8 mx-auto">
          See our team
        </Button>
      </section>
    </Layout>
  );
}

export const teamMemberQuery = graphql`
  query TeamMemberQuery($langRegex: String!) {
    layout: storyblokEntry(
      field_component: { eq: "common_layout" }
      full_slug: { regex: $langRegex }
    ) {
      content
    }
    sectionInvestmentsSettings: allStoryblokDatasourceEntry(
      filter: {
        data_source: { eq: "section-investments-settings" }
        data_source_dimension: { ne: null }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          name
          value
        }
      }
    }
  }
`;
