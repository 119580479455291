export const parseTeamMember = ({
  content,
  ...data
}: ParsedStoryblokStory<MemberType>): MemberType & {
  teamMemberPosition: string;
} => ({
  ...content,
  // workaround for Storyblok Visual Editor - as we have double 'position' param
  teamMemberPosition: content.position,
  ...data,
});

export const parseLayout = ({
  content,
  ...data
}: StoryblokStory): LayoutTemplate =>
  ({ ...JSON.parse(content), ...data } as LayoutTemplate);

export const parseProject = ({ content, ...data }: StoryblokStory) => {
  const parsedData = {
    ...JSON.parse(content),
    ...data,
  } as PortfolioProjectType;
  return {
    name: parsedData.project_name,
    description: parsedData.project_description,
    image: parsedData.project_image,
    ...parsedData,
  };
};
