import React from "react";
import { NewsEntry } from "@/components/sections/NewsroomSection/NewsEntry";
import { VideoCard } from "@/components/sections/VideoSection/VideoCard";
import NavButton from "../NavButton";
import Button from "../Button";

interface Props {
  relatedItems: (NewsPostType | SingleVideoType)[];
  showVideos: boolean;
  allNewsHref: string;
  isLinkingFromDifferentThanNews: boolean;
}

export default function RelatedNewsSection({
  allNewsHref,
  relatedItems,
  showVideos,
  isLinkingFromDifferentThanNews,
}: Props) {
  function isVideo(item: NewsPostType | SingleVideoType): boolean {
    return "video_date" in item.content;
  }

  return (
    <section className="bg-gray-100 py-24">
      <div className="flex justify-between items-center mb-16 mx-auto max-w-x-big px-4">
        <h2 className="mb-0">
          {showVideos ? "Related articles and videos" : "Related articles"}
        </h2>
        <NavButton
          className="max-md:hidden"
          title="See all posts"
          href={allNewsHref}
          toRight
        />
      </div>
      <ul className="flex gap-8 max-lg:flex-wrap mx-auto max-w-x-big px-4">
        {relatedItems.map((item) =>
          isVideo(item) ? (
            <div
              className="max-lg:w-full max-w-2xl lg:flex-1"
              key={item.internalId}
            >
              <VideoCard videoData={item.content as VideoType} />
            </div>
          ) : (
            <NewsEntry
              key={item.internalId}
              className="lg:flex-1 max-lg:w-full max-w-2xl"
              postData={
                { ...item, ...item.content } as unknown as NewsroomPostType
              }
              isLinkingFromDifferentThanNews={isLinkingFromDifferentThanNews}
            />
          )
        )}
      </ul>
      <Button type="link" to={allNewsHref} className="md:hidden mt-8 mx-auto">
        See all posts
      </Button>
    </section>
  );
}
