import React, { ReactNode } from "react";
import { Link } from "gatsby";
import clsx from "clsx";

interface BaseProps {
  children?: ReactNode;
  className?: string;
  type?: string;
}

interface LinkProps extends BaseProps {
  type: "link";
  to: string;
}

type Props = LinkProps;

export default function Button({ children, className = "", ...props }: Props) {
  const css = clsx(
    "block max-w-max bg-secondary text-white rounded-lg px-14 py-4 xl:px-12  relative xl:whitespace-pre border-secondary border-2 hover:text-secondary hover:bg-transparent transition-all",
    className
  );

  if (props.type === "link") {
    return (
      <Link to={props.to} className={css}>
        {children}
      </Link>
    );
  }

  return <button className={css}>{children}</button>;
}
