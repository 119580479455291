import React from "react";
import { Link } from "gatsby";
import clsx from "clsx";

interface Props {
  title: string;
  href: string;
  className?: string;
  toRight?: boolean;
}

export default function NavButton({
  title,
  href,
  className = "",
  toRight,
}: Props) {
  return (
    <Link
      to={href}
      className={clsx(
        "flex items-center text-secondary font-semibold gap-2",
        toRight && "flex-row-reverse",
        className
      )}
    >
      <span className={clsx("inline mr-3", toRight && "rotate-180")}>
        <svg
          width="34"
          height="10"
          viewBox="0 0 34 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 1L1 5M1 5L5 9M1 5H33"
            stroke="#4D63FF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>{" "}
      {title}
    </Link>
  );
}
